import { proxy } from 'valtio'
import { AnswerStatus, AnswerStatusStore } from '../answerStatusDrawer.tsx'
import { TrueOrFalseQuestion } from '../../../shared/api/chatApi.ts'

interface State {
  questions: readonly TrueOrFalseQuestion[]
  currentIndex: number
  currentAnswer?: boolean
  answerStatus?: AnswerStatus
  correctAnswersCount: number
}

export class TrueOrFalseExerciseStore {
  state = proxy<State>({
    questions: [],
    currentIndex: 0,
    correctAnswersCount: 0,
  })

  constructor(
    questions: readonly TrueOrFalseQuestion[],
    private answerStore: AnswerStatusStore,
    private onComplete: (result: string, correctAnswersCount: number) => void,
  ) {
    this.state.questions = questions
  }

  nextQuestion(): void {
    if (this.state.currentIndex < this.state.questions.length - 1) {
      this.state.currentIndex += 1
      setTimeout(() => {
        this.state.answerStatus = undefined
      }, 50)
    } else {
      this.state.answerStatus = AnswerStatus.complete()
      this.onComplete(
        'I have completed the exercise',
        this.state.correctAnswersCount,
      )
    }
  }

  answerQuestion(userAnswer: boolean) {
    if (this.state.answerStatus) {
      return
    }
    const answer = this.state.questions[this.state.currentIndex].isCorrect
    this.state.currentAnswer = userAnswer
    const status =
      userAnswer == answer
        ? AnswerStatus.correct()
        : AnswerStatus.wrong({
            description: 'Answer:',
            messageValue: answer ? 'True' : 'False',
          })
    userAnswer == answer && this.state.correctAnswersCount++
    this.state.answerStatus = status
    this.answerStore.setStatus(status, () => {
      this.nextQuestion()
    })
  }
}
