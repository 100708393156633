import {
  ListeningExerciseStore,
  ListeningQuestion,
} from './listeningExerciseStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import { useSnapshot } from 'valtio/react'
import {
  ExerciseButton,
  ExerciseContainer,
  ExerciseTitle,
} from '../exerciseComponents.tsx'
import { cn } from '../../../shared/lib/utils.ts'
import React from 'react'
import { useAppModule } from '../../appContext.ts'
import { WaveformProgressBar } from '../waveformProgressBar.tsx'
import { TaskSlider } from '../taskSlider/TaskSlider.tsx'
import { FormattedMessage } from 'react-intl'

interface ListeningExerciseProps {
  questions: readonly ListeningQuestion[]
  completed: boolean
  onComplete: (result: string, correctAnswersCount: number) => void
}
export const ListeningExercise = (props: ListeningExerciseProps) => {
  const answerStore = useAppModule().answerStatusStore
  const store = useLazyRef(
    () =>
      new ListeningExerciseStore(
        props.questions,
        answerStore,
        props.onComplete,
      ),
  ).current
  const state = useSnapshot(store.state)
  const currentQuestion = state.questions[state.currentIndex]

  return (
    <ExerciseContainer>
      <TaskSlider
        id={state.currentIndex}
        isLast={state.currentIndex == state.questions.length - 1}
      >
        <ExerciseTitle
          title={<FormattedMessage id="exercise.listen" />}
          className="mb-8"
        />
        <Player key={state.currentIndex} url={currentQuestion.soundUrl} />

        <div className="mt-40 h-1 w-full bg-gray2" />
        <div className="mt-56 text-center text-20 font-semibold">
          {currentQuestion.text}
        </div>

        <div className="mx-auto mt-56 flex w-full max-w-400 flex-col gap-8">
          {state.words.map((word, i) => {
            const isAnswer = state.currentAnswer?.index == i
            const isCorrect =
              state.currentAnswer && isAnswer
                ? state.currentAnswer.isCorrect
                : 'none'
            return (
              <ExerciseButton
                isCorrect={isCorrect}
                key={i}
                onClick={() => {
                  store.select(i)
                }}
              >
                {word}
              </ExerciseButton>
            )
          })}
        </div>
      </TaskSlider>
    </ExerciseContainer>
  )
}

function Player(props: { url: string }) {
  const speechService = useAppModule().speechService
  const [progress, setProgress] = React.useState(0)
  const [duration, setDuration] = React.useState(0)
  function play() {
    void speechService.play(1, props.url, (progress, duration) => {
      setProgress(progress)
      setDuration(duration)
    })
  }

  return (
    <div className="flex flex-col items-center gap-28 text-14 font-bold">
      <div className={cn(duration == 0 && 'invisible')}>
        <span className="text-main">{Math.ceil(duration * progress)}</span>
        <span className="text-gray3">
          {' / '}
          {Math.ceil(duration)}
        </span>{' '}
      </div>
      <div className="flex items-center gap-20">
        <WaveformProgressBar small={false} progress={progress} />
        <button
          onClick={() => {
            play()
          }}
        >
          <img src="/images/exercises/play.svg" />
        </button>
      </div>
    </div>
  )
}
