import { HttpClient } from './httpClient.ts'
import { PickPublic } from '../lib/utils.ts'

export interface TrueOrFalseQuestion {
  title: string
  isCorrect: boolean
}

export type SubscriptionPlan = '1M' | '3M' | '12M'

export interface LessonStages {
  RU: Record<string, string>
  EN: Record<string, string>
}
export interface Subscription {
  id: number
  amount: number
  plan: SubscriptionPlan
  active: boolean
  subscription_date: string
  days: number
  days_estimated: number
  recurrent: boolean
}
export interface MatchWord {
  word: string
  synonym: string
}

type FillTheGapsText = readonly (string | { word: string })[]
export interface FillTheGaps2Data {
  title: { RU: string; EN: string }
  text: FillTheGapsText
  options: readonly string[]
  incorrect: { RU: string; EN: string }
}

export type Listening = readonly {
  text: string
  sound_url: string
  options: readonly string[]
}[]
export type FillTheGaps = readonly (string | { word: string })[]
export type WriteTheGaps = readonly (string | { word: string; hint: string })[]
export const demoLessonTag = 'demo_lesson'
export interface Chat {
  id: number
  chat_title: string
  program_tag: string
  user: number
}

export interface Vocabulary {
  id: number
  term_text: string
  term_explain?: string
  term_translation?: string
  term_context?: string
  date_added: string
  date_last_reviewed: string
  program_tag: string
  topic: string
  is_user_added: boolean
}

export interface ApiOnboardingInfo {
  goal: string
  profession: string
  interests: readonly string[]
  sex: string
  age: string
  improves: readonly string[]
  duration: string
  level: string
}

export interface Lesson {
  id: number
  title: string
  user: number
  message_id: number
}

type ProgramItemStatus = 'new' | 'active' | 'completed' | 'closed'
export interface ProgramItem {
  id: number
  level: string
  lesson: string
  tag: string
  program: string
  topic: string
  display_program: string
  display_topic: string
  lesson_type: string
  lesson_name: string
  lesson_subject: string
  status: ProgramItemStatus
  order: number
}

export interface AiComment {
  text: string
  rule: string
  correct: string
}

export interface RateAiComment {
  rate: 1 | 2 | 3
  points?: { stage_points: number; exercise_points: number }
}

export type InfoBlockType = 'start_vocabulary_hint' | 'first_message'

export interface ChatMessage {
  id: number
  message_text: string
  is_ai: boolean
  picture_url?: string
  listening?: Listening
  fill_the_gaps?: readonly FillTheGaps[]
  fill_the_gaps2?: readonly FillTheGaps2Data[]
  article?: {
    body: string
    header: string
  }
  true_or_false?: readonly TrueOrFalseQuestion[]
  write_the_gaps?: readonly WriteTheGaps[]
  match_words?: readonly MatchWord[]
  ai_comment?: readonly AiComment[]
  ai_rate?: RateAiComment | null
  picture_urls?: readonly { url: string; title: string }[]
  parent_msg_id?: number
  info_block?: InfoBlockType | 'none'
  is_last: boolean
  is_hidden?: boolean
  translation?: string
  reply_hint?: {
    text: string
    translation: string
  }
  state?: string
}

export interface BalanceResponse {
  value: number
}

export interface Student {
  name: string
  level: 'A2' | 'B1' | 'B2'
  onboarding_info: ApiOnboardingInfo
  phone_number: string
  phone_confirmed: boolean
  date_joined?: string | null
  last_login?: string | null
  session_metadata: {
    programTag: string
  } | null
}

export interface Stat {
  total: number
  new: number
  active?: number
  vocab: { program: string; learned_count: number; total_count: number }[]
}

export interface UpdateStudent {
  name: string
  level: string
  onboarding_info: ApiOnboardingInfo
}

export interface PatchStudent {
  name?: string
  level?: string
  onboarding_info?: ApiOnboardingInfo
  ui_lang?: 'ru' | 'en'
  ui_device?: 'mobile' | 'desktop'
  session_metadata?: {
    programTag: string
  }
}

export interface CloudPaymentsPaymentData {
  publicId: string
  description: string
  amount: number
  currency: string
  invoiceId: string
  accountId: string
  email: string
  skin: string
  data: {
    cloudPayments: {
      recurrent?: {
        interval: string
        period: number
      }
    }
  }
}

function updateProgram(item: ProgramItem) {
  item.program = item.display_program
  item.topic = item.display_topic
  return item
}

export type ChatApi = PickPublic<AppChatApi>
export class AppChatApi {
  async allPrograms(): Promise<ProgramItem[]> {
    return this.httpClient
      .get<ProgramItem[]>('/api/programs/')
      .then((x) => x.map(updateProgram))
  }
  async userPrograms(): Promise<ProgramItem[]> {
    return this.httpClient
      .get<ProgramItem[]>('/api/user_programs/')
      .then((x) => x.map(updateProgram))
  }

  constructor(private httpClient: HttpClient) {}

  async version() {
    return this.httpClient.get<string>('/api/version/')
  }

  async chat_list() {
    return this.httpClient.get<Chat[]>('/api/chat_list/')
  }

  chat_create(request: {
    user: number
    chat_title: string
    program_tag: string
    language?: 'RU' | 'EN'
    recreate?: boolean
  }) {
    return this.httpClient.post<Chat>('/api/chat_create/', request)
  }

  message_create(request: { chat: number; message_text: string }) {
    return this.httpClient.post<ChatMessage>('/api/message_create/', request)
  }

  message_process(messageId: number) {
    return this.httpClient.get<ChatMessage[]>(
      `/api/message/${messageId}/process/`,
    )
  }

  message_translate(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/translate/`,
    )
  }

  message_reply_hint(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/reply_hint/`,
    )
  }

  message_orthography_check(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/orthography/`,
    )
  }

  async message_list(chatId: number): Promise<ChatMessage[]> {
    const response = await this.httpClient.get<ChatMessage[]>(
      `/api/message_list/${chatId}/`,
    )
    return response.map((x) => ({ ...x, is_ai: Boolean(x.is_ai) }))
  }

  async lesson_list(chatId: number): Promise<Lesson[]> {
    return await this.httpClient.get<Lesson[]>(`/api/lesson_list/${chatId}/`)
  }

  async message(messageId: number): Promise<ChatMessage> {
    return await this.httpClient.get<ChatMessage>(`/api/message/${messageId}/`)
  }

  async balance() {
    return await this.httpClient.get<BalanceResponse>(`/api/balance/`)
  }

  async getAccount() {
    return await this.httpClient.get<Student>(`/api/account/`)
  }

  async userProgramStages(tag: string) {
    return await this.httpClient.get<LessonStages>(
      `/api/user_program/stages/${tag}/`,
    )
  }

  async userProgramStage(tag: string) {
    return await this.httpClient.get<{ stage: string }>(
      `/api/user_program/stage/${tag}/`,
    )
  }

  async updateAccount(update: UpdateStudent) {
    return await this.httpClient.put<Student>(`/api/account/`, update)
  }

  async patchAccount(update: PatchStudent) {
    return await this.httpClient.patch<Student>(`/api/account/`, update)
  }

  async patchMessage(messageId: number, update: { duration_front: number }) {
    return await this.httpClient.patch<never>(
      `/api/message/${messageId}/`,
      update,
    )
  }

  async stat() {
    return await this.httpClient.get<Stat>(`/api/account/stat/`)
  }

  async voice_push(chatId: number, file: Blob) {
    //return Promise.resolve({done: true})
    const formData = new FormData()
    formData.append('file', file)
    return this.httpClient.request<{ done: boolean }>(
      '/api/voice_push/' + chatId + '/',
      {
        method: 'POST',
        body: formData,
        // headers: {
        //   'Content-Disposition': 'attachment; filename=file',
        //   'Content-Type': 'audio/wave',
        // },
      },
    )
  }

  vocabulary_add(request: { chat_id: number; term_text: string }) {
    return this.httpClient.post<Vocabulary>('/api/vocabulary/add/', request)
  }

  vocabulary_delete(vocabularyId: number) {
    return this.httpClient.del<never>(
      `/api/vocabulary/remove/${vocabularyId}/`,
      {},
    )
  }

  vocabulary(tag: string) {
    return this.httpClient.get<Vocabulary[]>(`/api/vocabulary/${tag}`)
  }

  message_rate(id: number, rating: number, user_comment?: string) {
    return this.httpClient.patch<never>(`/api/message_rate/${id}/`, {
      rating,
      user_comment,
    })
  }

  chat_rate(
    id: number,
    rating?: number,
    experience?: number,
    user_comment?: string,
  ) {
    return this.httpClient.patch<never>(`/api/chat_rate/${id}/`, {
      rating,
      experience,
      user_comment,
    })
  }

  subscribe(plan: SubscriptionPlan) {
    return this.httpClient.post<
      | {
          status: 'success'
          payment_data: CloudPaymentsPaymentData
          success_url: string
        }
      | {
          status: 'rejected'
          message: string
        }
    >('/api/subscribe/', { plan })
  }

  subscribe_trial(plan: SubscriptionPlan) {
    return this.httpClient.post<
      | {
          status: 'success'
          payment_data: CloudPaymentsPaymentData
          success_url: string
        }
      | {
          status: 'rejected'
          message: string
        }
    >('/api/subscribe_trial/', { plan })
  }

  unsubscribe(subscriptionId: number) {
    return this.httpClient.post<{ status: 'success' | 'reject' }>(
      `/api/unsubscribe/${subscriptionId}/`,
      {},
    )
  }

  subscriptions() {
    return this.httpClient.get<Subscription[]>(`/api/subscriptions`)
  }
}
