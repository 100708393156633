import { classed } from '@tw-classed/react'
import { useProfileStore } from './ProfileStore.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { cn } from '../../shared/lib/utils.ts'
import React from 'react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
} from '../../shared/ui/drawer/drawer.tsx'

export const ProfileMenuBlock = classed.div(
  'divide-y divide-gray rounded-18 bg-gray-light',
)

export function ProfileButton(props: {
  imageUrl?: string
  title: React.ReactNode
  alertTitle?: boolean
  onClick: () => void
  subtitle?: React.ReactNode
  withArrow?: boolean
  disabled?: boolean
}) {
  return (
    <button
      className="flex h-80 w-full items-center gap-16 overflow-hidden p-16"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.imageUrl && <img src={props.imageUrl} />}
      <div className="min-w-0 flex-1 text-left">
        <div
          className={cn(
            'truncate text-18 font-bold',
            props.alertTitle && 'text-alert',
          )}
        >
          {props.title}
        </div>
        {props.subtitle && (
          <div className="text-14 font-semibold text-light">
            {props.subtitle}
          </div>
        )}
      </div>
      {props.withArrow && <Icon className="text-main" iconName="arrowRight" />}
    </button>
  )
}

export function ProfileTitle(props: {
  title: string | React.ReactNode
  hideBack?: boolean
}) {
  const store = useProfileStore()
  return (
    <div className="mb-24 flex gap-16">
      {!props.hideBack && (
        <button
          className="shrink-0"
          onClick={() => {
            store.goBack()
          }}
        >
          <Icon iconName="left" className="text-black" />
        </button>
      )}
      <h1
        className={cn(
          'text-18 font-bold text-black',
          props.hideBack && 'w-full text-center',
        )}
      >
        {props.title}
      </h1>
    </div>
  )
}

export function ProfileDrawer(props: {
  title: string | React.ReactNode
  children: React.ReactNode
  open: boolean
  gray?: boolean
}) {
  const store = useProfileStore()
  return (
    <Drawer
      open={props.open}
      onOpenChange={(open) => {
        if (!open) {
          store.closeDrawer()
        }
      }}
    >
      <DrawerContent
        direction="bottom"
        className={cn(
          'mx-auto mt-24 flex h-auto max-w-800 flex-col rounded-t-32',
          props.gray ? 'bg-light' : 'bg-white',
        )}
      >
        <DrawerBar />
        <div className="flex flex-col">
          <div className="flex items-center justify-between px-32 pt-16">
            <div className="text-20 font-bold">{props.title}</div>
            <DrawerClose asChild>
              <div className="flex size-32 items-center justify-center rounded-full bg-gray">
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
          </div>
          <div className="w-full px-32 py-16">{props.children}</div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
